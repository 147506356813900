import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-intl";

const Timeline = styled.div`
  background-color: #435d74;
  height: 3px;
  transform: rotate(-10deg);
`;

const TimelineConnect = styled.div`
  height: 40px;
  width: 3px;
  background-color: #435d74;
  margin-left: auto;
  margin-right: auto;
`;

const TimelineContainer = styled.div`
  padding-bottom: 3em;
  padding-top: 6em;
`;

const TimelineHeader = styled.p`
  color: #53575a;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const TimelineItem = styled.div`
  transform: rotate(10deg);
  @media (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const TimelineItems = styled.div`
  transform: rotate(-10deg);
`;

const TimelineYear = styled.p`
  color: #e31a21;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const IndexAbout = () => (
  <section id="hakkimizda" className="feature-sec padding-top padding-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 text-center">
          <div className="text">
            <div className="home-text text-black">
              <h4 className="heading">
                <FormattedMessage id="aboutHeading" defaultMessage="Hakkımızda" />
              </h4>
              <p className="text">
                <FormattedMessage
                  id="aboutContent"
                  defaultMessage="Unver Lastik, 2010 yılında Unver Grup şirketler grubu bünyesinde Petlas bayisi olarak faaliyete başlamış olup 2016 yılında, 'Unver Otomotiv Sanayi Ticaret Anonim Şirketi' olarak açılışını gerçekleştirmiştir.

                  Bu tarihten itibaren Continental ve General Tire bayiliğini de alan şirketimiz, 2019 yılına geldiğimizde Pirelli, Michelin ve Goodyear markaları ile iş birliğine başlamıştır.
                  
                  Toptan, Perakende ve Filo kanallarında faaliyet gösteren Unver Lastik, bugün 3 farklı lokasyonda toplam 2.500 m2 kapalı alan ile müşterilerine en kaliteli ve güvenilir hizmeti vermeyi ilke edinmiştir.
                  
                  Otomobil, 4X4 SUV, Hafif Ticari, Kamyonet, Kamyon-Otobüs, Zirai Grup, İş Makinaları ve Forklift gruplarında sunduğu satış ve servis hizmetlerinin yanı sıra lastik oteli, jant, akü ve periyodik bakım gibi hizmetleri de müşterileriyle buluşturmaktadır."
                  values={{
                    br: (
                      <>
                        <br />
                        <br />
                      </>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <TimelineContainer className="row">
        <div className="col-12">
          <TimelineItems className="row" style={{ paddingRight: "10%" }}>
            <TimelineItem className="col-3">
              <TimelineHeader>
                Petlas <br /> <FormattedMessage id="dealer" defaultMessage="Bayilik" />
              </TimelineHeader>
              <TimelineYear>2010</TimelineYear>
              <TimelineConnect />
            </TimelineItem>
            <TimelineItem className="col-3">
              <TimelineHeader>
                Continental <br /> <FormattedMessage id="dealer" defaultMessage="Bayilik" />
              </TimelineHeader>
              <TimelineYear>2016</TimelineYear>
              <TimelineConnect />
            </TimelineItem>
            <TimelineItem className="col-3">
              <TimelineHeader>
                Pirelli <br /> <FormattedMessage id="dealer" defaultMessage="Bayilik" />
              </TimelineHeader>
              <TimelineYear>2019</TimelineYear>
              <TimelineConnect />
            </TimelineItem>
            <TimelineItem className="col-3">
              <TimelineHeader>
                Michelin <br /> <FormattedMessage id="dealer" defaultMessage="Bayilik" />
              </TimelineHeader>
              <TimelineYear>2019</TimelineYear>
              <TimelineConnect />
            </TimelineItem>
          </TimelineItems>
          <Timeline />
          <TimelineItems className="row" style={{ paddingLeft: "10%" }}>
            <TimelineItem className="col-3">
              <TimelineConnect />
              <TimelineYear>2013</TimelineYear>
              <TimelineHeader>
                Unver Lastik <br /> (Bursa)
              </TimelineHeader>
            </TimelineItem>
            <TimelineItem className="col-3">
              <TimelineConnect />
              <TimelineYear>2016</TimelineYear>
              <TimelineHeader>
                General Tire <br /> <FormattedMessage id="dealer" defaultMessage="Bayilik" />
              </TimelineHeader>
            </TimelineItem>
            <TimelineItem className="col-3">
              <TimelineConnect />
              <TimelineYear>2019</TimelineYear>
              <TimelineHeader>
                Goodyear <br /> <FormattedMessage id="dealer" defaultMessage="Bayilik" />
              </TimelineHeader>
            </TimelineItem>
            <TimelineItem className="col-3">
              <TimelineConnect />
              <TimelineYear>2020</TimelineYear>
              <TimelineHeader>
                <FormattedMessage
                  id="heavyVehicleService"
                  defaultMessage="Ağır Vasıta Grubu Satış ve Servis"
                />
              </TimelineHeader>
            </TimelineItem>
          </TimelineItems>
        </div>
      </TimelineContainer>
    </div>
  </section>
);

export default IndexAbout;

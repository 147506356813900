import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

import { SITE_TITLE, VERSION_ID } from "../constants";

const Header = () => (
  <Helmet>
    <title>{SITE_TITLE}</title>
    <meta
      name="description"
      content="Unver Lastik toptan, perakende ve filo kanallarında faaliyet gösteren Bursa merkezli bir satış ve servis sağlayıcısıdır."
    />
    <meta
      name="keywords"
      content="unver lastik, lastik, toptan lastik, perakende lastik, filo lastik, bursa lastik, kış lastiği, yaz lastiği, petlas, pirelli, michelin, goodyear"
    />
    <link href={withPrefix("vendor/css/bundle.min.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link
      href={withPrefix("design-agency/css/line-awesome.min.css?v=" + VERSION_ID)}
      rel="stylesheet"
    />
    <link
      href={withPrefix("vendor/css/revolution-settings.min.css?v=" + VERSION_ID)}
      rel="stylesheet"
    />
    <link
      href={withPrefix("vendor/css/jquery.fancybox.min.css?v=" + VERSION_ID)}
      rel="stylesheet"
    />
    <link href={withPrefix("vendor/css/owl.carousel.min.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("vendor/css/cubeportfolio.min.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("vendor/css/LineIcons.min.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("design-agency/css/slick.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("design-agency/css/slick-theme.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("vendor/css/wow.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("minimal-startup/css/style.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("design-agency/css/blog.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("design-agency/css/style.css?v=" + VERSION_ID)} rel="stylesheet" />
    <link href={withPrefix("overrides.css?v=" + VERSION_ID)} rel="stylesheet" />
  </Helmet>
);

export default Header;

import React from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL, SITE_TITLE } from "../constants";

const Header = styled.header`
  height: 84px;
  @media (max-width: 768px) {
    height: 62px !important;
  }
`;

const Logo = styled.a`
  position: static !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  width: 160px !important;
  @media (max-width: 768px) {
    margin-left: 8px !important;
  }
`;

const LogoImage = styled.img`
  max-height: 32px !important;
  height: 32px !important;
  max-width: 160px !important;
  width: 160px !important;
  @media (max-width: 768px) {
    margin-left: 0 !important;
  }
`;

const NavBar = styled.nav`
  margin-left: 2em !important;
`;

const NavLanguageLinkIcon = styled.i`
  margin-right: 4px;
`;

const NavLink = styled.a`
  min-width: auto !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  position: relative;
`;

const NavLinkIndicator = styled.span`
  color: white;
  visibility: hidden;
  font-size: 18px;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: -18px;
`;

const SideMenu = styled.div`
  background-color: #535759 !important;
  background-image: none !important;
`;

const SideMenuLink = styled.a`
  text-transform: none !important;
`;

const SideMenuLogo = styled.a`
  width: auto !important;
`;

const Toggle = styled.a`
  left: auto !important;
  right: 18px !important;
`;

const NavLanguageLink = () => {
  const { locale } = useIntl();
  return (
    <NavLink className="nav-link" href={locale === "tr" ? "/en" : "/"}>
      <NavLanguageLinkIcon className="fa fa-language fa-s" />
      {locale === "tr" ? "English" : "Türkçe"}
    </NavLink>
  );
};

const SideMenuLanguageLink = () => {
  const { locale } = useIntl();
  return (
    <SideMenuLink className="nav-link" href={locale === "tr" ? "/en" : "/"}>
      <NavLanguageLinkIcon className="fa fa-language fa-s" />
      {locale === "tr" ? "English" : "Türkçe"}
    </SideMenuLink>
  );
};

const IndexHeader = () => (
  <Header id="giris" className="cursor-light">
    <div className="inner-header nav-icon">
      <div className="main-navigation">
        <div className="container">
          <div className="row">
            <div className="col-8 col-lg-10 simple-navbar d-flex align-items-center justify-content-start">
              <Logo className="navbar-brand scroll" href="#giris">
                <LogoImage
                  alt={SITE_TITLE}
                  className="logo-simple"
                  height={32}
                  src={require("../images/logo.png")}
                  width={160}
                />
              </Logo>
              <NavBar className="navbar navbar-expand-lg">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <div className="navbar-nav ml-auto">
                    <NavLink className="nav-link scroll" href="#hakkimizda">
                      <NavLinkIndicator className="nav-link-indicator" style={{ width: 148 }}>
                        <i aria-hidden="true" className="fa fa-angle-down"></i>
                      </NavLinkIndicator>
                      <FormattedMessage id="aboutHeading" defaultMessage="Hakkımızda" />
                    </NavLink>
                    <NavLink className="nav-link scroll" href="#markalarimiz">
                      <NavLinkIndicator className="nav-link-indicator" style={{ width: 158 }}>
                        <i aria-hidden="true" className="fa fa-angle-down"></i>
                      </NavLinkIndicator>
                      <FormattedMessage id="brandsHeading" defaultMessage="Markalarımız" />
                    </NavLink>
                    <NavLink className="nav-link scroll" href="#iletisim">
                      <NavLinkIndicator className="nav-link-indicator" style={{ width: 106 }}>
                        <i aria-hidden="true" className="fa fa-angle-down"></i>
                      </NavLinkIndicator>
                      <FormattedMessage id="contactHeading" defaultMessage="İletişim" />
                    </NavLink>
                    <NavLanguageLink />
                  </div>
                </div>
              </NavBar>
            </div>
            <div className="col-lg-2 px-0 simple-navbar align-items-center justify-content-end d-none d-lg-flex">
              <div className="navbar-social side-icons d-flex justify-content-center">
                <a className="social-icon" href={FACEBOOK_URL} target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="social-icon" href={INSTAGRAM_URL} target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="social-icon" href={LINKEDIN_URL} target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toggle href="javascript:void(0)" className="sidemenu_btn" id="sidemenu_toggle">
        <span></span>
        <span></span>
      </Toggle>
    </div>
    {/* <!--Side Nav--> */}
    <SideMenu className="side-menu hidden side-menu-opacity">
      <div className="inner-wrapper">
        <span className="btn-close" id="btn_sideNavClose">
          <i></i>
          <i></i>
        </span>
        <SideMenuLogo href="#giris" className="navbar-brand side-logo scroll">
          <LogoImage
            alt={SITE_TITLE}
            className="logo-simple"
            height={32}
            src={require("../images/logo.png")}
            width={160}
          />
        </SideMenuLogo>
        <nav className="side-nav w-100">
          <ul className="navbar-nav">
            <li className="nav-item">
              <SideMenuLink className="nav-link scroll" href="#hakkimizda">
                <FormattedMessage id="aboutHeading" defaultMessage="Hakkımızda" />
              </SideMenuLink>
            </li>
            <li className="nav-item">
              <SideMenuLink className="nav-link scroll" href="#markalarimiz">
                <FormattedMessage id="brandsHeading" defaultMessage="Markalarımız" />
              </SideMenuLink>
            </li>
            <li className="nav-item">
              <SideMenuLink className="nav-link scroll" href="#iletisim">
                <FormattedMessage id="contactHeading" defaultMessage="İletişim" />
              </SideMenuLink>
            </li>
            <li className="nav-item">
              <SideMenuLanguageLink />
            </li>
          </ul>
        </nav>
        <div className="side-footer text-white w-100 d-flex justify-content-center">
          <ul className="social-icons-simple">
            <li>
              <a className="facebook-text-hvr" href={FACEBOOK_URL} target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a className="instagram-text-hvr" href={INSTAGRAM_URL} target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a className="linkedin-text-hvr" href={LINKEDIN_URL} target="_blank">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </SideMenu>
    <a id="close_side_menu" href="javascript:void(0);"></a>
  </Header>
);

export default IndexHeader;

import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import styled from "styled-components";

const ContactInfo = styled.div`
  padding-top: 45px;
`;

const ContactRow = styled.div`
  margin-top: 5em;
`;

const ContentParagraph = styled.p`
  font-family: "Raleway", sans-serif;
  margin-bottom: 5px;
  padding-left: 40px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const Icon = styled.i`
  color: #e31921;
`;

const IconSpan = styled.span`
  text-align: center;
  width: 40px;
  @media (max-width: 768px) {
    margin-right: 8px;
    width: auto;
  }
`;

const Label = styled.b`
  color: #53585a;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
`;

const LabelParagraph = styled.p`
  margin-bottom: 0;
`;

const PlaceImage = styled.img``;

const PlaceName = styled.p`
  color: #e31921;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1em;
  @media (max-width: 768px) {
    text-align: center !important;
  }
`;

const WorkingHours = styled.b`
  color: #53585a;
  font-size: 0.9em;
`;

const IndexContact = () => (
  <section id="iletisim" className="feature-sec padding-top padding-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 text-center">
          <div className="text">
            <div className="home-text text-black">
              <h4 className="heading">
                <FormattedMessage id="contactHeading" defaultMessage="İletişim" />
              </h4>
            </div>
          </div>
        </div>
      </div>
      <ContactRow className="row contact-reverse">
        <ContactInfo className="col-12 col-lg-6 wow slideInLeft">
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-map-marker-alt" />
            </IconSpan>
            <Label>
              <FormattedMessage id="address" defaultMessage="Adres" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>
            Ahıska Cad. Beşevler Mah. Güllü Sok. 8/B. <br /> 16110 Nilüfer/Bursa,{" "}
            <FormattedMessage id="turkey" defaultMessage="Türkiye" />
          </ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-phone" />
            </IconSpan>
            <Label>
              <FormattedMessage id="phone" defaultMessage="Telefon" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>+90 (224) 441 99 09</ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-envelope" />
            </IconSpan>
            <Label>
              <FormattedMessage id="mail" defaultMessage="E-posta" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>info@unverlastik.com</ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-home" />
            </IconSpan>
            <Label>
              <FormattedMessage id="workingHours" defaultMessage="Çalışma Saatleri" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>
            <FormattedMessage id="mondayFriday" defaultMessage="Pazartesi-Cuma" />
            <WorkingHours> 08:30-18:30</WorkingHours>
            <br />
            <FormattedMessage id="saturday" defaultMessage="Cumartesi" />
            <WorkingHours> 08:30-17:30</WorkingHours>
            <br />
            <FormattedMessage id="sunday" defaultMessage="Pazar" />
            <WorkingHours>
              {" "}
              <FormattedMessage id="closed" defaultMessage="Kapalı" />
            </WorkingHours>
          </ContentParagraph>
        </ContactInfo>
        <div className="col-12 col-lg-6 wow slideInRight">
          <PlaceName style={{ textAlign: "right" }}>
            <FormattedMessage id="hq" defaultMessage="Merkez Ofis" />
          </PlaceName>
          <PlaceImage src={require("../images/contact/hq.jpg")} />
        </div>
      </ContactRow>
      <ContactRow className="row">
        <div className="col-12 col-lg-6 wow slideInLeft">
          <PlaceName>
            <FormattedMessage id="continental" defaultMessage="Continental" />
          </PlaceName>
          <PlaceImage src={require("../images/contact/continental.jpg")} />
        </div>
        <ContactInfo className="col-12 col-lg-6 wow slideInRight">
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-map-marker-alt" />
            </IconSpan>
            <Label>
              <FormattedMessage id="address" defaultMessage="Adres" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>
            Ahıska Cad. Beşevler Mah. Güllü Sok. 8/A. <br /> 16110 Nilüfer/Bursa,{" "}
            <FormattedMessage id="turkey" defaultMessage="Türkiye" />
          </ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-phone" />
            </IconSpan>
            <Label>
              <FormattedMessage id="phone" defaultMessage="Telefon" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>+90 (224) 441 99 09</ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-envelope" />
            </IconSpan>
            <Label>
              <FormattedMessage id="mail" defaultMessage="E-posta" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>info@unverlastik.com</ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-home" />
            </IconSpan>
            <Label>
              <FormattedMessage id="workingHours" defaultMessage="Çalışma Saatleri" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>
            <FormattedMessage id="mondayFriday" defaultMessage="Pazartesi-Cuma" />
            <WorkingHours> 08:30-18:30</WorkingHours>
            <br />
            <FormattedMessage id="saturday" defaultMessage="Cumartesi" />
            <WorkingHours> 08:30-17:30</WorkingHours>
            <br />
            <FormattedMessage id="sunday" defaultMessage="Pazar" />
            <WorkingHours>
              {" "}
              <FormattedMessage id="closed" defaultMessage="Kapalı" />
            </WorkingHours>
          </ContentParagraph>
        </ContactInfo>
      </ContactRow>
      <ContactRow className="row contact-reverse">
        <ContactInfo className="col-12 col-lg-6 wow slideInLeft">
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-map-marker-alt" />
            </IconSpan>
            <Label>
              <FormattedMessage id="address" defaultMessage="Adres" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>
            Minareliçavuş Mah. Münevver Sok. <br /> 16140 Nilüfer/Bursa,{" "}
            <FormattedMessage id="turkey" defaultMessage="Türkiye" />
          </ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-phone" />
            </IconSpan>
            <Label>
              <FormattedMessage id="phone" defaultMessage="Telefon" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>+90 (224) 441 99 09</ContentParagraph>
          <LabelParagraph>
            <IconSpan>
              <Icon className="fas fa-envelope" />
            </IconSpan>
            <Label>
              <FormattedMessage id="mail" defaultMessage="E-posta" />
            </Label>
          </LabelParagraph>
          <ContentParagraph>info@unverlastik.com</ContentParagraph>
        </ContactInfo>
        <div className="col-12 col-lg-6 wow slideInRight">
          <PlaceName style={{ textAlign: "right" }}>
            <FormattedMessage id="storage" defaultMessage="Depo" />
          </PlaceName>
          <PlaceImage src={require("../images/contact/storage.jpg")} />
        </div>
      </ContactRow>
    </div>
  </section>
);

export default IndexContact;

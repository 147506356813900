import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-intl";

const Brand = styled.div`
  padding-bottom: 2em;
  padding-top: 2em;
  text-align: center;
`;

const BrandImage = styled.img`
  max-height: 42px;
`;

const Category = styled.div`
  margin-bottom: 1em;
  margin-top: 0.5em;
  padding-bottom: 1em;
  padding-top: 1em;
  position: relative;
`;

const CategoryImage = styled.img`
  max-height: 32px;
`;

const CategoryItem = styled.div`
  text-align: center;
`;

const CategoryName = styled.p`
  color: #53585a;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 1rem;
  padding-top: 6px;
`;

const CategorySeparator = styled.div`
  background-color: #b5b8bb;
  height: 85px;
  position: absolute;
  right: 0;
  top: 0;
  width: 3px;
`;

const IndexBrands = () => (
  <section id="markalarimiz" className="feature-sec bg-light-gray padding-top padding-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 text-center">
          <div className="text">
            <div className="home-text text-black">
              <h4 className="heading">
                <FormattedMessage id="brandsHeading" defaultMessage="Markalarımız" />{" "}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/auto.png")} />
              <CategoryName>
                <FormattedMessage id="categorySedan" defaultMessage="Binek" />
              </CategoryName>
            </CategoryItem>
            <CategorySeparator />
          </Category>
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/suv.png")} />
              <CategoryName>
                <FormattedMessage id="categorySUV" defaultMessage="SUV" />
              </CategoryName>
            </CategoryItem>
            <CategorySeparator />
          </Category>
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/commercial.png")} />
              <CategoryName>
                <FormattedMessage id="categoryCommercial" defaultMessage="Hafif Ticari" />
              </CategoryName>
            </CategoryItem>
            <CategorySeparator />
          </Category>
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/semitruck.png")} />
              <CategoryName>
                <FormattedMessage id="categoryPickup" defaultMessage="Kamyonet" />
              </CategoryName>
            </CategoryItem>
            <CategorySeparator />
          </Category>
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/truck.png")} />
              <CategoryName>
                <FormattedMessage id="categoryTruck" defaultMessage="Kamyon" />
              </CategoryName>
            </CategoryItem>
            <CategorySeparator />
          </Category>
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/agri.png")} />
              <CategoryName>
                <FormattedMessage id="categoryAgri" defaultMessage="Zirai" />
              </CategoryName>
            </CategoryItem>
            <CategorySeparator />
          </Category>
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/forklift.png")} />
              <CategoryName>
                <FormattedMessage id="categoryForklift" defaultMessage="Forklift" />
              </CategoryName>
            </CategoryItem>
            <CategorySeparator />
          </Category>
          <Category className="col">
            <CategoryItem>
              <CategoryImage src={require("../images/icons/workmachine.png")} />
              <CategoryName>
                <FormattedMessage id="categoryEarthmove" defaultMessage="İş Makinesi" />
              </CategoryName>
            </CategoryItem>
          </Category>
        </div>
      </div>
      <div className="row">
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/continental.png")} />
        </Brand>
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/dunlop.png")} />
        </Brand>
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/falken.png")} />
        </Brand>
      </div>
      <div className="row">
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/generaltire.png")} />
        </Brand>
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/goodyear.png")} />
        </Brand>
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/michelin.png")} />
        </Brand>
      </div>
      <div className="row">
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/milestone.png")} />
        </Brand>
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/petlas.png")} />
        </Brand>
        <Brand className="col-12 col-lg-4">
          <BrandImage src={require("../images/brands/pirelli.png")} />
        </Brand>
      </div>
      <div className="row">
        <Brand className="col-12 ">
          <BrandImage src={require("../images/brands/starmaxx.png")} />
        </Brand>
      </div>
    </div>
  </section>
);

export default IndexBrands;

import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-intl";

import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } from "../constants";

const Footer = styled.footer`
  padding-bottom: 5em;
  padding-top: 0;
`;

const IndexFooter = () => (
  <Footer className="bg-white">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 text-center">
          <ul className="footer-icons mb-4">
            <li>
              <a href={FACEBOOK_URL} className="wow fadeInUp facebook" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href={INSTAGRAM_URL} className="wow fadeInUp instagram" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href={LINKEDIN_URL} className="wow fadeInDown linkedin" target="_blank">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
          <p className="copyrights mt-2 mb-2">
            <FormattedMessage
              id="copyright"
              defaultMessage="© 2022 Unver Otomotiv. Tüm Hakları Saklıdır."
            />
          </p>
        </div>
      </div>
    </div>
  </Footer>
);

export default IndexFooter;

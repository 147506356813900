import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-intl";

import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } from "../constants";

const B2BButton = styled.a`
  border-color: #53585a !important;
  color: #53585a;
  cursor: pointer;
  font-family: "Raleway", sans-serif !important;
`;

const B2BHeading = styled.h4`
  color: #cfd0d1 !important;
`;

const B2BBox = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 80px !important;
    padding-top: 80px !important;
  }
`;

const B2CButton = styled.a`
  border-color: white !important;
  color: white !important;
  cursor: pointer;
  font-family: "Raleway", sans-serif !important;
`;

const B2CBox = styled.div`
  background-color: #cd1313 !important;
  @media (max-width: 768px) {
    padding-bottom: 80px !important;
    padding-top: 80px !important;
  }
`;

const B2CHeading = styled.h4`
  color: #db5555 !important;
`;

const MainBox = styled.div`
  background-color: #535759 !important;
  background-image: url(${require("../images/slider.jpg")});
  background-size: cover;
`;

const MainHeading = styled.h4`
  color: #dbdbdc !important;
  font-style: italic;
  word-break: break-word;
`;

const Slider = styled.section`
  padding: 0;
`;

const SliderRow = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 0 !important;
    padding-top: 62px !important;
  }
`;

const SocialLinkIcon = styled.i`
  position: relative;
  top: -1px;
`;

const SocialLinkItem = styled.li`
  display: block !important;
  margin: 0 !important;
  margin-bottom: 5px !important;
  padding: 0 !important;
`;

const SocialLinkList = styled.ul`
  margin: 0 !important;
`;

const SocialLinks = styled.div`
  left: 10px;
  position: absolute;
  top: 42.5vh;
  @media (max-width: 768px) {
    display: none !important;
  }
`;

const IndexBanner = () => (
  <Slider className="banner-slider position-relative cursor-light" id="banner-slider">
    <SliderRow className="row banner-slider-row no-gutters">
      <MainBox
        className="col-12 col-lg-8 bg-blue banner-left-area d-flex justify-content-center align-items-center wow slideInLeft"
        data-wow-delay=".5s"
      >
        <div className="left-banner">
          <div className="container">
            <div className="detail-text">
              <div className="inner-content position-relative text-center" data-depth="0.05">
                <MainHeading className="heading">
                  <FormattedMessage
                    id="mainHeading"
                    defaultMessage="HER KOŞULDA {br} HEP YANINDA"
                    values={{
                      br: <br />,
                    }}
                  />
                </MainHeading>
              </div>
            </div>
          </div>
        </div>
      </MainBox>
      <div className="col-12 col-lg-4 banner-right-area wow slideInRight" data-wow-delay=".5s">
        <div className="row no-gutters">
          <B2BBox className="col-12 bg-white height-50 d-flex align-items-center slider-right-box">
            <div className="container-fluid">
              <div className="detail-text text-right ml-auto mr-4">
                <span className="sub-heading"></span>
                <B2BHeading className="heading">
                  <FormattedMessage id="b2bHeading" defaultMessage="BAYİ GİRİŞİ" />
                </B2BHeading>
                <p className="text" style={{ color: "inherit" }}>
                  <FormattedMessage
                    id="b2bContent"
                    defaultMessage="Aşağıdaki tuşu kullanarak bayi girişi gerçekleştirebilir ve B2B ekranlarına ulaşabilirsiniz."
                  />
                </p>
                <B2BButton
                  className="btn rounded-pill"
                  href="http://b2b.unverlastik.com:81/"
                  target="_blank"
                >
                  <FormattedMessage id="b2bButton" defaultMessage="Bayi Girişi Yap" />
                </B2BButton>
              </div>
            </div>
          </B2BBox>
          <B2CBox className="col-12 bg-green height-50 d-flex align-items-center slider-right-box">
            <div className="container-fluid">
              <div className="detail-text text-left mr-auto ml-4">
                <span className="sub-heading"></span>
                <B2CHeading className="heading">
                  <FormattedMessage id="b2cHeading" defaultMessage="ONLINE SATIŞ" />
                </B2CHeading>
                <p className="text">
                  <FormattedMessage
                    id="b2cContent"
                    defaultMessage="Online satış mağazamız üzerinden alışverişlerinizi gerçekleştirebilirsiniz."
                  />
                </p>
                <B2CButton className="btn rounded-pill">
                  <FormattedMessage id="b2cButton" defaultMessage="Çok Yakında" />
                </B2CButton>
              </div>
            </div>
          </B2CBox>
        </div>
      </div>
    </SliderRow>
    <SocialLinks className="text-white">
      <SocialLinkList className="social-icons-simple">
        <SocialLinkItem>
          <a className="facebook-text-hvr" href={FACEBOOK_URL} target="_blank">
            <SocialLinkIcon className="fab fa-facebook-f"></SocialLinkIcon>
          </a>
        </SocialLinkItem>
        <SocialLinkItem>
          <a className="instagram-text-hvr" href={INSTAGRAM_URL} target="_blank">
            <SocialLinkIcon className="fab fa-instagram"></SocialLinkIcon>
          </a>
        </SocialLinkItem>
        <SocialLinkItem>
          <a className="linkedin-text-hvr" href={LINKEDIN_URL} target="_blank">
            <SocialLinkIcon className="fab fa-linkedin-in"></SocialLinkIcon>
          </a>
        </SocialLinkItem>
      </SocialLinkList>
    </SocialLinks>
  </Slider>
);

export default IndexBanner;

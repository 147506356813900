import React from "react";
import styled from "styled-components";
import { withPrefix } from "gatsby";

import { VERSION_ID } from "../constants";

const GoTopButton = styled.div`
  background-color: #535759 !important;
  &::before {
    background-color: #535759 !important;
  }
`;

const Footer = () => (
  <>
    <GoTopButton className="go-top">
      <i className="fas fa-angle-up"></i>
      <i className="fas fa-angle-up"></i>
    </GoTopButton>
    <script src={withPrefix("init.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/bundle.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/jquery.appear.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/jquery.fancybox.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/owl.carousel.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/parallaxie.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/wow.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/jquery.cubeportfolio.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("vendor/js/TweenMax.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("design-agency/js/circle-progress.min.js?v=" + VERSION_ID)}></script>
    <script src={withPrefix("minimal-startup/js/script.js?v=" + VERSION_ID)}></script>
  </>
);

export default Footer;

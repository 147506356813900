import React from "react";

import Footer from "../components/footer";
import Header from "../components/header";
import IndexAbout from "../components/index-about";
import IndexBanner from "../components/index-banner";
import IndexBrands from "../components/index-brands";
import IndexContact from "../components/index-contact";
import IndexHeader from "../components/index-header";
import IndexFooter from "../components/index-footer";

const IndexPage = () => {
  return (
    <>
      <Header />
      <IndexHeader />
      <main>
        <IndexBanner />
        <IndexAbout />
        <IndexBrands />
        <IndexContact />
        <IndexFooter />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
